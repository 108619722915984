import React, { useCallback, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { push } from 'redux-first-router';
import { debounce } from '@poly/utils';
import { AddReportSuccess, GeneralError } from '../../constants/alerts.js';
import AddMasterWorkOrder from '../../components/project/add-master-work-order.js';
import { setReportModal } from '../../redux/actions/index.js';
import {
  checkRepeatsValue,
  formatPeriods,
} from '../../utils/mr-projects/index.js';
import { omitBlockedSuppliersQuery } from '../../utils/suppliers/suppliers-filter.js';
import { useClientDetails } from '../../hooks/client.js';
import {
  useAddRecurringWOMutation,
  useServiceTypes,
} from '../../hooks/projects.js';
import { useManagers, useTechnicians } from '../../hooks/staff.js';
import { useSuppliers } from '../../hooks/suppliers.js';
import { usePropertiesQuery } from '../../hooks/properties.js';
import { useCommonFormLogic, useMasterWOCommonHandlers } from './hooks.js';
import { useRefHandlers } from '../../hooks/useRefHandlers.js';
import { initialFormData } from './constants.js';
import { getRequestDataForCreateMasterWO } from './helpers.js';

function AddMasterWOModal(props) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const clientId = user?.clientId;
  const clientConfig = user?.clientConfig;
  const supplierSource = clientConfig?.supplierSource;

  const {
    searchTerm,
    search,
    searchService,
    setSearch,
    setPropertySearch,
    setServiceSearch,
    onInputChange,
  } = useMasterWOCommonHandlers();

  const { client } = useClientDetails(clientId);

  const { serviceTypes, serviceLoading } = useServiceTypes(
    supplierSource,
    searchService,
  );

  const { technicians } = useTechnicians(searchTerm, clientId);

  const { managers } = useManagers(searchTerm, clientId);

  const { allSuppliers } = useSuppliers({
    searchTerm,
    query: omitBlockedSuppliersQuery,
  });

  const { createRecurringProject } = useAddRecurringWOMutation();

  const { propertyLoading, properties, noPropertyResults } =
    usePropertiesQuery(search);

  const {
    projectManager,
    projectSuppliers,
    projectTechnician,
    startDate,
    endDate,
    repeatEvery,
    selectedProperty,
    selectedService,
    repeatTime,
    repeatNum,
    repeats,
    setLoading,
    isNever,
    setRepeatEvery,
    isProjectAssigned,
    setError,
    validate,
    ...restCommonFormLogicProps
  } = useCommonFormLogic({ clientConfig, initialFormData });

  useEffect(() => {
    if (repeats.length > 0 && startDate) {
      setRepeatEvery(repeats[0]);
    }
  }, [repeats, startDate]);

  const { setRef, getRef } = useRefHandlers();

  const reportModalHandler = (projId) => {
    setLoading(false);
    setError(null);
    dispatch(setReportModal(null));
    push(`/mr-project/${projId}`);
    toast.success(AddReportSuccess);
  };

  const debouncedReportModal = useCallback(
    debounce(1000)(reportModalHandler),
    [],
  );

  const closeModal = useCallback(
    () => dispatch(setReportModal(null)),
    [dispatch, setReportModal],
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);

      const startDateObj = moment.isMoment(startDate)
        ? startDate.toDate()
        : startDate;
      const endDateObj = moment.isMoment(endDate) ? endDate.toDate() : endDate;

      const errorsValue = validate({
        property: selectedProperty?.value,
        service: selectedService,
        description: e.target.description.value,
        manager: { clientConfig, projectManager },
        endDate: { startDate: startDateObj, endDate: endDateObj, isNever },
        startDate: startDateObj,
      });

      if (errorsValue.isInvalid) {
        setLoading(false);
        return false;
      }

      const dayChecker = !!repeatEvery && !!checkRepeatsValue(repeatEvery);

      const schedule = {
        repeats: formatPeriods(repeatTime),
        every: repeatNum,
        weekDay: dayChecker,
        days: dayChecker ? [checkRepeatsValue(repeatEvery)] : [],
      };

      const requestData = getRequestDataForCreateMasterWO({
        isNever,
        selectedService,
        startDateObj,
        endDateObj,
        selectedProperty,
        projectManager,
        isProjectAssigned,
        projectSuppliers,
        projectTechnician,
        getRef,
        event: e,
      });

      return createRecurringProject({ ...requestData, clientId, schedule })
        .then(
          ({
            data: {
              createRecurringProject: {
                recurringProject: { projectId },
              },
            },
          }) => {
            debouncedReportModal(projectId);
          },
        )
        .catch((errorObj) => {
          setLoading(false);
          setError({ server: errorObj.message });
          toast.error(GeneralError);
        });
    },
    [
      clientId,
      repeatEvery,
      repeatTime,
      repeatNum,
      startDate,
      endDate,
      isNever,
      selectedProperty,
      selectedService,
      getRef,
      dispatch,
      setError,
      validate,
      setLoading,
      projectManager,
      projectSuppliers,
      projectTechnician,
      isProjectAssigned,
      createRecurringProject,
      clientConfig,
    ],
  );

  return (
    <AddMasterWorkOrder
      {...props}
      {...restCommonFormLogicProps}
      isProjectAssigned={isProjectAssigned}
      setRef={setRef}
      isNever={isNever}
      closeModal={closeModal}
      propertyLoading={propertyLoading}
      client={client}
      repeatNum={repeatNum}
      repeatTime={repeatTime}
      startDate={startDate}
      endDate={endDate}
      selectedProperty={selectedProperty}
      selectedService={selectedService}
      repeats={repeats}
      repeatEvery={repeatEvery}
      serviceTypes={serviceTypes}
      properties={properties}
      onSubmit={onSubmit}
      managers={managers}
      technicians={technicians}
      allSuppliers={allSuppliers}
      projectTechnician={projectTechnician}
      projectManager={projectManager}
      projectSuppliers={projectSuppliers}
      setSearch={setSearch}
      onInputChange={onInputChange}
      setPropertySearch={setPropertySearch}
      setRepeatEvery={setRepeatEvery}
      serviceLoading={serviceLoading}
      setServiceSearch={setServiceSearch}
      noPropertyResults={noPropertyResults}
      title="Create New Master Recurring Work Order"
      submitBtnCaption="Create WO"
    />
  );
}

export default function (props) {
  const isModalOpen = useSelector((state) => state.report.modal);

  return isModalOpen ? <AddMasterWOModal {...props} /> : null;
}
