import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { SupplierEmailsTypes, SupplierStatuses } from '@poly/constants';
import { Loader } from '@poly/site-ui';
import SupplierDetails from '../../components/supplier/details.js';
import {
  companyPhoneFormatter,
  emailFormatter,
} from '../../utils/suppliers/index.js';

import { useSupplierDetailsSub } from '../../hooks/suppliers.js';

const { SERVICE, ACCOUNT } = SupplierEmailsTypes;

// prepareSupplierDetails :: Supplier -> PreparedSupplierDetails
// PreparedSupplierDetails = {
//    phone: [SupplierPhone]
//    status: String
//    remarks: JSON
//    services: [ServiceType]
//    addressTwo: String
//    addressParts: AddressParts
//    serviceEmails: [SupplierEmail]
//    accountingEmails: [SupplierEmail]
// }
const prepareSupplierDetails = R.applySpec({
  phone: R.compose(companyPhoneFormatter, R.pathOr([], ['company', 'phones'])),
  status: R.propOr(SupplierStatuses.ACTIVE, 'status'),
  remarks: R.propOr('', 'remarks'),
  services: R.pathOr([], ['company', 'services']),
  addressTwo: R.pathOr(null, ['company', 'addressTwo']),
  addressParts: R.pathOr(null, ['company', 'address', 'address_parts']),
  serviceEmails: R.compose(
    (emails) => emailFormatter(emails, SERVICE),
    R.pathOr([], ['company', 'emails']),
  ),
  accountingEmails: R.compose(
    (emails) => emailFormatter(emails, ACCOUNT),
    R.pathOr([], ['company', 'emails']),
  ),
});

export default function (props) {
  const supplierId = useSelector((state) => state.location.payload.id);

  const propsOfComponent = { ...props, supplierId };
  const { restProps } = useSupplierDetailsSub(propsOfComponent);

  if (restProps.loading) return <Loader />;

  const {
    phone,
    status,
    remarks,
    services,
    addressTwo,
    addressParts,
    serviceEmails,
    accountingEmails,
  } = prepareSupplierDetails(restProps?.supplier);

  return (
    <SupplierDetails
      {...props}
      {...restProps}
      status={status}
      remarks={remarks}
      addressTwo={addressTwo}
      addressParts={addressParts}
      services={services}
      phone={phone}
      serviceEmails={serviceEmails}
      accountingEmails={accountingEmails}
    />
  );
}
