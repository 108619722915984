import { endOfDay, startOfDay } from 'date-fns';
import { ensureIsDate } from '@poly/utils';
import {
  RecurringProjectStatuses,
  RecurringProjectTypes,
  ProjectSpendTypes,
  RWOTaskListType,
  ChildType,
} from '@poly/constants';

import { getValue } from '../../util/general.js';

// checkIsNever :: Object, Bool -> Date || Null
const checkIsNever = (date, isNever) =>
  isNever ? null : new Date(endOfDay(ensureIsDate(date)));

// getStaffData :: Object -> Object
const getStaffData = ({
  projectSuppliers,
  projectTechnician,
  isProjectAssigned,
}) =>
  isProjectAssigned
    ? {
        suppliers: projectSuppliers.map(({ _id }) => ({
          supplierId: _id,
        })),
        technicians: projectTechnician ? [projectTechnician._id] : [],
      }
    : {};

// getRequestDataForEditMasterWO :: Object -> Object
export function getRequestDataForEditMasterWO({
  getRef,
  isNever,
  event: e,
  endDateObj,
  startDateObj,
  selectedStatus,
  projectManager,
  selectedService,
  selectedProperty,
  projectSuppliers,
  isProjectAssigned,
  projectTechnician,
}) {
  return {
    status: selectedStatus,
    serviceTypeId: selectedService,
    managerId: projectManager?._id,
    location: getValue('location', e),
    propertyId: selectedProperty.value,
    description: getValue('description', e),
    endDate: checkIsNever(endDateObj, isNever),
    startDate: startOfDay(ensureIsDate(startDateObj)),
    payload: {
      [ChildType.TM]: {
        ...getStaffData({
          projectSuppliers,
          projectTechnician,
          isProjectAssigned,
        }),
        invoiceDescription: getRef().getEditor().getContents(),
      },
    },
  };
}

// getRequestDataForCreateMasterWO :: Object -> Object
export function getRequestDataForCreateMasterWO({
  getRef,
  isNever,
  event: e,
  endDateObj,
  startDateObj,
  projectManager,
  selectedService,
  selectedProperty,
  projectSuppliers,
  isProjectAssigned,
  projectTechnician,
}) {
  return {
    childType: ChildType.TM,
    serviceTypeId: selectedService,
    disableChildrenGeneration: false,
    endDate: checkIsNever(endDateObj, isNever),
    location: getValue('location', e),
    propertyId: selectedProperty.value,
    description: getValue('description', e),
    status: RecurringProjectStatuses.ACTIVE,
    taskListType: RWOTaskListType.SHORT_LIST,
    spendType: ProjectSpendTypes.REACTIVE_REPAIR,
    type: RecurringProjectTypes.recurringProject,
    startDate: startOfDay(ensureIsDate(startDateObj)),
    ...(projectManager && { managerId: projectManager._id }),
    payload: {
      [ChildType.TM]: {
        invoiceDescription: getRef().getEditor().getContents(),
        ...getStaffData({
          projectSuppliers,
          projectTechnician,
          isProjectAssigned,
        }),
      },
    },
  };
}
