import 'isomorphic-unfetch';
import { getSentryApolloLinks } from '@poly/client-utils/src/sentry/sentry-link.js';
import { handleApplicationMaintenance } from '@poly/client-utils';
import { createHandleApolloErrorAtSiteUI } from '@poly/site-ui';
import { createApolloClient } from '@poly/apollo-client';

import logOut from '../thunks/logOut.js';

export const apolloClient = createApolloClient(
  logOut,
  createHandleApolloErrorAtSiteUI(logOut),
  handleApplicationMaintenance,
  { additionalAfterWareLinks: getSentryApolloLinks(process.env.SENTRY_DSN) },
);
