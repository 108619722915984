import * as R from 'ramda';
import { keywordSortQuery } from '@poly/client-utils';

import { fieldFormatter } from '../../../../util/general.js';
import { propertyUserFormatter } from '../../../../util/property-utils.js';
import { peopleStatusFormatter } from '../../../../utils/suppliers/constructions.js';

export const peopleColumns = (dispatch, action) => [
  [
    'Name',
    R.identity,
    propertyUserFormatter(dispatch, action),
    R.path(['profile', 'fullName']),
    keywordSortQuery(['profile', 'fullName']),
  ],
  [
    'Title',
    R.prop('title'),
    fieldFormatter,
    R.path(['title']),
    keywordSortQuery(['title']),
  ],
  [
    'Status',
    R.prop('status'),
    peopleStatusFormatter,
    R.path(['status']),
    keywordSortQuery(['status']),
  ],
];
